import "./App.css";
import DashboardComponent from "./components/Dashboard";
import Login from "./components/Login";
import SchoolTypeMasterComp from "./components/SchoolTypeMaster";
import ForgotPasswordComponent from "./components/ForgotPassword";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import CreateNewPassword from "./components/CreateNewPassword";
import OtpVerification from "./components/OtpVerification";
import ProgramMaster from "./components/ProgramMaster";
import PlatformUserTypeMaster from "./components/PlatformUserTypeMaster";
import PlatformUserTypeMasterForm from "./components/PlatformUserTypeMaster/PlatformUserTypeMasterForm";
import SchoolFacilitiesMaster from "components/SchoolFacilitiesMaster";
import { ROUTE_CONSTANTS } from "constants/routeConstant";
import SubjectMaster from "components/SubjectMaster";
import GradeMaster from "components/GradeMaster";
import GradeMasterForm from "./components/GradeMaster/GradeMasterForm";
import MasterManagement from "components/MasterManagement";
import ProgramMasterForm from "components/ProgramMaster/ProgramMasterForm";
import RequestManagement from "components/RequestManagement";
import SubjectMasterForm from "components/SubjectMaster/SubjectMasterForm";
import SchoolFacilitiesForm from "components/SchoolFacilitiesMaster/SchoolFacilitiesForm";
import BannerManagement from "components/BannerManagement";
import { STRING_CONSTANT } from "constants/common";

const router = createBrowserRouter([
  {
    path: ROUTE_CONSTANTS.HOME,
    element: <Login />,
  },
  {
    path: ROUTE_CONSTANTS.DASHBOARD,
    element: <DashboardComponent />,
  },
  {
    path: ROUTE_CONSTANTS.FORGOT_PASSWORD,
    element: <ForgotPasswordComponent />,
  },
  {
    path: "/school-type-master",
    element: <SchoolTypeMasterComp />,
  },
  {
    path: ROUTE_CONSTANTS.CREATE_NEW_PASSWORD,
    element: <CreateNewPassword />,
  },
  {
    path: ROUTE_CONSTANTS.OTP_VERIFICATION,
    element: <OtpVerification />,
  },
  {
    path: ROUTE_CONSTANTS.PROGRAM_MASTER,
    element: <ProgramMaster />,
  },
  {
    path: ROUTE_CONSTANTS.PLATFORM_USER_TYPE_MASTER,
    element: <PlatformUserTypeMaster />,
  },
  {
    path: ROUTE_CONSTANTS.VIEW_PLATFORM_USER_TYPE_MASTER,
    element: (
      <PlatformUserTypeMasterForm
        isView
        formHeading={STRING_CONSTANT.VIEW_PLATFORM_USER_TYPE_MASTER}
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.ADD_PLATFORM_USER_TYPE_MASTER,
    element: (
      <PlatformUserTypeMasterForm
        isAdd
        formHeading={STRING_CONSTANT.ADD_PLATFORM_USER_TYPE_MASTER}
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.EDIT_PLATFORM_USER_TYPE_MASTER,
    element: (
      <PlatformUserTypeMasterForm
        isEdit
        formHeading={STRING_CONSTANT.EDIT_PLATFORM_USER_TYPE_MASTER}
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.SCHOOL_FACILITIES_MASTER,
    element: <SchoolFacilitiesMaster />,
  },
  {
    path: ROUTE_CONSTANTS.SUBJECT_MASTER,
    element: <SubjectMaster />,
  },
  {
    path: ROUTE_CONSTANTS.GRADE_MASTER,
    element: <GradeMaster />,
  },
  {
    path: ROUTE_CONSTANTS.EDIT_GRADE_MASTER,
    element: (
      <GradeMasterForm isEdit formHeading={STRING_CONSTANT.EDIT_GRADE_MASTER} />
    ),
  },
  {
    path: ROUTE_CONSTANTS.ADD_GRADE_MASTER,
    element: (
      <GradeMasterForm isAdd formHeading={STRING_CONSTANT.ADD_GRADE_MASTER} />
    ),
  },
  {
    path: ROUTE_CONSTANTS.VIEW_GRADE_MASTER,
    element: (
      <GradeMasterForm isView formHeading={STRING_CONSTANT.VIEW_GRADE_MASTER} />
    ),
  },
  {
    path: ROUTE_CONSTANTS.MATER_MANAGEMENT,
    element: <MasterManagement />,
  },
  {
    path: ROUTE_CONSTANTS.ADD_PROGRAM_MASTER,
    element: (
      <ProgramMasterForm
        isAdd
        formHeading={STRING_CONSTANT.ADD_PROGRAM_MASTER}
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.EDIT_PROGRAM_MASTER,
    element: (
      <ProgramMasterForm
        isEdit
        formHeading={STRING_CONSTANT.EDIT_PROGRAM_MASTER}
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.VIEW_PROGRAM_MASTER,
    element: (
      <ProgramMasterForm
        isView
        formHeading={STRING_CONSTANT.VIEW_PROGRAM_MASTER}
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.REQUEST_MANAGEMENT,
    element: <RequestManagement />,
  },
  {
    path: ROUTE_CONSTANTS.ADD_SUBJECT_MASTER,
    element: (
      <SubjectMasterForm
        isAdd
        formHeading={STRING_CONSTANT.ADD_SUBJECT_MASTER}
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.EDIT_SUBJECT_MASTER,
    element: (
      <SubjectMasterForm
        isEdit
        formHeading={STRING_CONSTANT.EDIT_SUBJECT_MASTER}
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.VIEW_SUBJECT_MASTER,
    element: (
      <SubjectMasterForm
        isView
        formHeading={STRING_CONSTANT.VIEW_SUBJECT_MASTER}
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.ADD_SCHOOL_FACILITIES,
    element: (
      <SchoolFacilitiesForm
        isAdd
        formHeading={STRING_CONSTANT.ADD_SCHOOL_FACILITIES}
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.EDIT_SCHOOL_FACILITIES,
    element: (
      <SchoolFacilitiesForm
        isEdit
        formHeading={STRING_CONSTANT.EDIT_SCHOOL_FACILITIES}
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.VIEW_SCHOOL_FACILITIES,
    element: (
      <SchoolFacilitiesForm
        isView
        formHeading={STRING_CONSTANT.VIEW_SCHOOL_FACILITIES}
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.BANNER_MANAGEMENT,
    element: <BannerManagement />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
