import Input from "components/CustomInput";
import { useForm } from "hooks/useForm";
import React from "react";
import { useNavigate } from "react-router-dom";

const AddNation = () => {
  const initialState = {
    email: {
      value: "",
      //   required: true,
      error: "",
    },
    password: {
      value: "",
      error: "",
    },
  };

  const handleAddNation = (params, error) => {
    // logic for save
  };

  const { errors, handleOnSubmit, handleOnChange } = useForm(
    initialState,
    handleAddNation
  );

  return (
    <>
      <Input
        name="nameInEnglish"
        type="text"
        placeholder="Name (English)"
        onChangeHandler={handleOnChange}
        label="Name (English)"
      />
      <Input
        name="nameInFrench"
        type="text"
        placeholder="Name (French)"
        onChangeHandler={handleOnChange}
        label="Name (French)"
      />
    </>
  );
};

export default AddNation;
