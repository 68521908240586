import React from "react";
import { EyeOpenIcon, IconDelete, IconEdit } from "assets/images/SVGs";
import { CustomSwitch } from "../CustomSwitch";
import styles from "./tableActionButtonStyles.module.css";
import { useNavigate } from "react-router-dom";

const RenderTableActionsButton = ({
  switchChecked,
  handleOnSwitch,
  handleEditRoute,
  showViewIcon,
  handleDeleteClick,
  handleViewRoute,
  showEditIcon,
  showDeleteIcon,
}) => {
  const navigate = useNavigate();

  return (
    <div className={styles.action_button_wrapper}>
      {showEditIcon ? (
        <IconEdit
          width={15}
          height={15}
          onClick={() => navigate(handleEditRoute)}
        />
      ) : null}

      {showViewIcon ? (
        <EyeOpenIcon
          width={20}
          height={20}
          onClick={() => navigate(handleViewRoute)}
        />
      ) : null}

      {showDeleteIcon ? (
        <IconDelete
          width={15}
          height={15}
          onClick={() => handleDeleteClick()}
        />
      ) : null}

      <CustomSwitch
        fieldName={"isStatus"}
        title={""}
        isChecked={switchChecked}
        onChangeHandler={handleOnSwitch}
      />
    </div>
  );
};

export default RenderTableActionsButton;
