import React from "react";
import styles from "../CustomInput/inputStyles.module.css";

const Textarea = ({ label, placeholder, value, onChange }) => {
  return (
    <div className={styles.input_wrapper}>
      <label className={styles.label_styles}>{label}</label>
      <textarea
        className={styles.input}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        rows="10"
      />
    </div>
  );
};

export default Textarea;
