import RenderTableActionsButton from "components/RenderTableActionsButton";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import RenderRequestManagementFilters from "./RenderRequestManagementFilters";
import { CustomTable } from "components/CustomTable";
import { STRING_CONSTANT } from "constants/common";
import CustomModal from "components/CustomModal";
import MainLayout from "layouts/MainLayout";
import { CustomSwapButton } from "components/CustomSwapButton";

const RequestManagement = () => {
  const navigate = useNavigate();
  const [isFilter, setIsFilter] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);
  const defaultModalProps = { isOpen: false, data: null };
  const [modalOpen, setModalOpen] = useState(false);

  const handleAccept = () => {};

  const handleReject = () => {};

  const columns = [
    {
      field: "ministerID",
      header: "Minister ID",
    },
    {
      field: "ministryType",
      header: "Ministry Type",
      enableSort: true,
    },
    { field: "ministerName", header: "School Category" },
    {
      field: "email",
      header: "Email",
    },
    {
      field: "phoneNumber",
      header: "Phone Number,",
    },

    {
      field: "IdProofImage",
      header: "ID proof image",
    },
    {
      field: "passportImage",
      header: "Passport image",
    },
    {
      field: "dlImage",
      header: "DL image",
    },
    {
      field: "createdDate",
      header: "Created date",
    },
    {
      field: "updatedDate",
      header: "Updated date",
    },
    {
      field: "acceptReject",
      header: "Accept/Reject",
      shouldRenderElement: true,
      renderElement: () => {
        return (
          <CustomSwapButton
            acceptLabel="Yes"
            rejectLabel="No"
            buttonHeight="25"
            buttonWidth="50"
            onAccept={handleAccept}
            onReject={handleReject}
            isDisabled={false}
          />
        );
      },
    },
    {
      field: "action",
      header: "Actions",
      shouldRenderElement: true,
      renderElement: () => {
        return (
          <RenderTableActionsButton
            setModalOpen={setModalOpen}
            switchChecked={switchChecked}
            handleOnSwitch={handleOnSwitch}
            handleDeleteClick={() => setModalOpen({ data, isOpen: true })}
            showViewIcon
            showEditIcon
            showDeleteIcon
          />
        );
      },
    },
  ];

  const data = [
    {
      ministerID: "1",
      ministryType: "John Brown",
      ministerName: "avc",
      phoneNumber: "jkj",
      email: "formal",
      IdProofImage: "12/09/2024",
      passportImage: "18/10/2024",
    },
    {
      ministerID: "2",
      ministryType: "Jim Green",
      ministerName: "avc",
      phoneNumber: "jkj",
      email: "formal",
      IdProofImage: "12/09/2024",
      passportImage: "18/10/2024",
    },
    {
      ministerID: "3",
      ministryType: "Joe Black",
      ministerName: "avc",
      phoneNumber: "jkj",
      email: "formal",
      IdProofImage: "12/09/2024",
      passportImage: "18/10/2024",
    },
    {
      ministerID: "2",
      ministryType: "Jim Green",
      ministerName: "avc",
      phoneNumber: "jkj",
      email: "formal",
      IdProofImage: "12/09/2024",
      passportImage: "18/10/2024",
    },
    {
      ministerID: "3",
      ministryType: "Joe Black",
      ministerName: "avc",
      phoneNumber: "jkj",
      email: "formal",
      IdProofImage: "12/09/2024",
      passportImage: "18/10/2024",
    },
    {
      ministerID: "2",
      ministryType: "Jim Green",
      ministerName: "avc",
      phoneNumber: "jkj",
      email: "formal",
      IdProofImage: "12/09/2024",
      passportImage: "18/10/2024",
    },
    {
      ministerID: "3",
      ministryType: "Joe Black",
      ministerName: "avc",
      phoneNumber: "jkj",
      email: "formal",
      IdProofImage: "12/09/2024",
      passportImage: "18/10/2024",
    },
    {
      ministerID: "2",
      ministryType: "Jim Green",
      ministerName: "avc",
      phoneNumber: "jkj",
      email: "formal",
      IdProofImage: "12/09/2024",
      passportImage: "18/10/2024",
    },
  ];

  const onChangeData = () => {};

  const handleDateChange = (date, dateString) => {};

  const handleMultiSelectChange = (value) => {};

  const multiSelectOptions = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
  ];

  const handleIsFilter = () => {
    setIsFilter(!isFilter);
  };

  const handleAddRoute = (params, error) => {};

  const handleOnSwitch = (e, key) => {
    setSwitchChecked(() => e?.target?.checked);
  };

  return (
    <MainLayout>
      {modalOpen.isOpen ? (
        <CustomModal
          modalOpen={modalOpen.isOpen}
          setModalOpen={() => setModalOpen(defaultModalProps)}
          modalTitle={"Delete Modal"}
          modalInnerContent={STRING_CONSTANT.RECORD_DELETE_STRING}
          footerBtnText={"Yes"}
        />
      ) : null}
      <CustomTable
        showAddButton={true}
        handleAddRoute={handleAddRoute}
        isFilter={isFilter}
        addBtnText={"Add"}
        tableHeader={"Request Management"}
        handleIsFilter={handleIsFilter}
        filterOptions={
          <RenderRequestManagementFilters
            showDateFilter={true}
            showMultiSelectFilter={true}
            multiSelectOptions={multiSelectOptions}
          />
        }
        data={data}
        columns={columns}
        count={10}
        wministerIDth={"100"}
        noDataFoundMessage={"not found"}
        class={"table_class"}
        onChangeData={onChangeData}
        btnMarginTop={false}
      />
    </MainLayout>
  );
};

export default RequestManagement;
