import styled from "styled-components";

const SidebarMainWrapper = styled.div`
  width: 100%;
`;

const SidebarList = styled.ul`
  list-style: none;
  padding: 0;
  display: block;
  margin: 0;
`;

const SidebarItem = styled.li`
  border-bottom: 1px solid #929aab70;
  background-color: ${({ isActive }) =>
    isActive ? " #393e4614" : "transparent"};
  cursor: pointer;

  &:hover {
    background-color: #393e4614;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SideNavMenuDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ isCollapsed }) => (isCollapsed ? "center" : "")};
  gap: 1rem;
  padding: 1rem;
`;

const SideNavMenuItemSpan = styled.div`
  text-decoration: none;
  color: #393e46;
  font-size: 0.80551em;
  font-weight: 500;
  position: relative;
  display: ${({ isCollapsed }) => (!isCollapsed ? "block" : "none")};
`;

export const SideNavStyledComponent = {
  SidebarMainWrapper,
  SidebarList,
  SidebarItem,
  IconWrapper,
  SideNavMenuDiv,
  SideNavMenuItemSpan,
};
