import MainLayout from "layouts/MainLayout";
import React, { useState } from "react";
import { CustomTable } from "components/CustomTable";
import { IconEdit } from "assets/images/SVGs";

const DashboardComponent = () => {
  const columns = [
    {
      field: "schoolName",
      header: "School Name",
      enableSort: true,
    },
    { field: "boardName", header: "Board Name" },
    { field: "studentName", header: "Student Name" },
    {
      field: "status",
      header: "Status",
    },
    {
      field: "action",
      header: "Actions",
    },
  ];

  const data = [
    {
      key: "1",
      schoolName: "John Brown",
      boardName: "CBSE",
      studentName: "Riddhi Parmar",
      status: "Active",
      action: (
        <div>
          edit <IconEdit width={20} height={20} />
        </div>
      ),
    },
    {
      key: "2",
      schoolName: "Jim Green",
      boardName: "SCC",
      status: "Active",
      studentName: "Varun Rokade",
    },
    {
      key: "3",
      schoolName: "Joe Black",
      boardName: "CBSE",
      status: "Active",
      studentName: "Akanksha Yadav",
    },
    {
      key: "2",
      schoolName: "Jim Green",
      boardName: "SCC",
      status: "Inactive",
      studentName: "Varun Rokade",
    },
    {
      key: "3",
      schoolName: "Joe Black",
      boardName: "CBSE",
      status: "Inactive",
      studentName: "Akanksha Yadav",
    },
    {
      key: "2",
      schoolName: "Jim Green",
      boardName: "SCC",
      status: "Active",
      studentName: "Varun Rokade",
    },
    {
      key: "3",
      schoolName: "Joe Black",
      boardName: "CBSE",
      status: "Inactive",
      studentName: "Akanksha Yadav",
    },
    {
      key: "2",
      schoolName: "Jim Green",
      boardName: "SCC",
      status: "Active",
      studentName: "Varun Rokade",
    },
  ];

  const onChangeData = () => {};

  const handleDateChange = (date, dateString) => {};

  const handleMultiSelectChange = (value) => {};

  const multiSelectOptions = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
  ];

  const [isFilter, setIsFilter] = useState(false);

  const handleIsFilter = () => {
    setIsFilter(!isFilter);
  };

  return (
    <MainLayout>
      <CustomTable
        isFilter={isFilter}
        tableHeader={"Dashboard"}
        handleIsFilter={handleIsFilter}
        data={data}
        columns={columns}
        count={10}
        width={"100"}
        noDataFoundMessage={"not found"}
        class={"table_class"}
        onChangeData={onChangeData}
      />
    </MainLayout>
  );
};

export default DashboardComponent;
