import React from "react";
import { Select } from "antd";
import inputStyles from "components/CustomInput/inputStyles.module.css";
import "./dropdownStyles.css";

const { Option } = Select;

const Dropdown = ({
  options = [],
  label,
  placeholder = "Please select",
  onChange,
  value,
  mode,
  allowClear = true,
  disabled = false,
  defaultValue,
  ...restProps
}) => {
  
  return (
    <div className={inputStyles.input_wrapper}>
      <label className={inputStyles.label_styles}>{label}</label>
      <Select
        className="custom-select"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        mode={mode}
        allowClear={allowClear}
        disabled={disabled}
        defaultValue={defaultValue}
        {...restProps}
      >
        {options.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default Dropdown;
