import {
  Dashboard,
  FacilitiesMaster,
  GradeMaster,
  MasterManagement,
  ProgramMaster,
  RequestManagement,
  SchoolTypeMaster,
  SubjectMaster,
  UserTypeMaster,
} from "assets/images/SVGs";
import { ROUTE_CONSTANTS } from "./routeConstant";

export const MENULIST = [
  {
    id: 1,
    icon: <Dashboard width={18} height={18} />,
    text: "Dashboard",
    path: ROUTE_CONSTANTS.DASHBOARD,
  },
  {
    id: 2,
    icon: <SchoolTypeMaster width={18} height={18} />,
    text: "Schools Type Master",
    path: ROUTE_CONSTANTS.SCHOOL_TYPE_MASTER,
  },
  {
    id: 3,
    icon: <ProgramMaster width={18} height={18} />,
    text: "Program Master",
    path: ROUTE_CONSTANTS.PROGRAM_MASTER,
  },
  {
    id: 4,
    icon: <UserTypeMaster width={18} height={18} />,
    text: "Platform User Type Master",
    path: ROUTE_CONSTANTS.PLATFORM_USER_TYPE_MASTER,
  },
  {
    id: 5,
    icon: <FacilitiesMaster width={18} height={18} />,
    text: "School Facilities Master",
    path: ROUTE_CONSTANTS.SCHOOL_FACILITIES_MASTER,
  },
  {
    id: 6,
    icon: <SubjectMaster width={18} height={18} />,
    text: "Subject Master",
    path: ROUTE_CONSTANTS.SUBJECT_MASTER,
  },
  {
    id: 7,
    icon: <GradeMaster width={18} height={18} />,
    text: "Grade Master",
    path: ROUTE_CONSTANTS.GRADE_MASTER,
  },
  {
    id: 8,
    icon: <MasterManagement width={18} height={18} />,
    text: "Master Management",
    path: ROUTE_CONSTANTS.MATER_MANAGEMENT,
  },
  {
    id: 8,
    icon: <RequestManagement width={18} height={18} />,
    text: "Request Management",
    path: ROUTE_CONSTANTS.REQUEST_MANAGEMENT,
  },
  {
    id: 9,
    icon: <RequestManagement width={18} height={18} />,
    text: "Banner Management",
    path: ROUTE_CONSTANTS.BANNER_MANAGEMENT,
  },
];
