import styled from "styled-components";

const FilterDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.3125rem 0.95rem;
  border-radius: 5px;
  border: ${({ isFilter }) =>
    isFilter ? "1px solid #828690" : "1px solid #929aab70"};
  margin-top: 1rem;
`;

const StyledTableBody = styled.tbody`
  height: 20%;
`;

const StyledTable = styled.table`
  width: ${({ width }) => (width ? width + "%" : "115%")};
`;

const CustomTableWrapper = styled.div`
  padding: 1rem;
  background: #eeeeee;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 5px;
`;

const StyledComponents = {
  FilterDiv,
  StyledTableBody,
  StyledTable,
  CustomTableWrapper,
};

export default StyledComponents;
