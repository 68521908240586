export const ROUTE_CONSTANTS = {
  HOME: "/",
  DASHBOARD: "/dashboard",
  FORGOT_PASSWORD: "/forgot-password",
  CREATE_NEW_PASSWORD: "/create-new-password",
  OTP_VERIFICATION: "/otp-verification",
  SCHOOL_TYPE_MASTER: "/school-type-master",
  PROGRAM_MASTER: "/program-master",
  ADD_PROGRAM_MASTER: "/add-program-master-route",
  EDIT_PROGRAM_MASTER: "/edit-program-master-route",
  VIEW_PROGRAM_MASTER: "/view-program-master-route",
  PLATFORM_USER_TYPE_MASTER: "/platform-user-type-master",
  EDIT_PLATFORM_USER_TYPE_MASTER: "/edit-platform-user-type-master",
  VIEW_PLATFORM_USER_TYPE_MASTER: "/view-platform-user-type-master",
  ADD_PLATFORM_USER_TYPE_MASTER: "/add-platform-user-type-master",
  SCHOOL_FACILITIES_MASTER: "/school-facilities-master",
  SUBJECT_MASTER: "/subject-master",
  GRADE_MASTER: "/grade-master",
  EDIT_GRADE_MASTER: "/edit-grade-master",
  ADD_GRADE_MASTER: "/add-grade-master",
  VIEW_GRADE_MASTER: "/view-grade-master",
  MATER_MANAGEMENT: "/master-management",
  ADD_MASTER_MANAGEMENT: "/add-master-management",
  REQUEST_MANAGEMENT: "/request-management",
  ADD_SUBJECT_MASTER: "/add-subject-master",
  EDIT_SUBJECT_MASTER: "/edit-subject-master",
  VIEW_SUBJECT_MASTER: "/view-subject-master",
  ADD_SCHOOL_FACILITIES: "/add-school-facilities",
  EDIT_SCHOOL_FACILITIES: "/edit-school-facilities",
  VIEW_SCHOOL_FACILITIES: "/view-school-facilities",
  BANNER_MANAGEMENT: "/banner-management",
};
