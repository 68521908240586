import React from "react";
import MainLayout from "layouts/MainLayout";
import Input from "components/CustomInput";
import { useForm } from "hooks/useForm";
import Textarea from "components/CustomTextarea";
import FormLayout from "layouts/FormLayout";
import { useNavigate } from "react-router-dom";
import { StyledFlex } from "constants/commonStyles";
import { ROUTE_CONSTANTS } from "constants/routeConstant";

const PlatformUserTypeMasterForm = ({ formHeading, isView }) => {
  const navigate = useNavigate();
  const initialState = {
    email: {
      value: "",
      //   required: true,
      error: "",
    },
    password: {
      value: "",
      error: "",
    },
  };

  const loginHandler = (params, error) => {};

  const { errors, handleOnSubmit, handleOnChange } = useForm(
    initialState,
    loginHandler
  );

  return (
    <MainLayout>
      <FormLayout
        formHeading={formHeading}
        handleOnSubmit={handleOnSubmit}
        cancelBtnText={"Cancel"}
        cancelBtnRoute={"/platform-user-type-master"}
        saveBtnText={"Save"}
        isView={isView}
        viewButtonText={"Edit"}
        viewFormEditRoute={ROUTE_CONSTANTS.EDIT_PLATFORM_USER_TYPE_MASTER}
      >
        <StyledFlex>
          <Input
            name="userType"
            type="text"
            placeholder="User Type"
            onChangeHandler={handleOnChange}
            label="User Type"
          />
          <Input
            name="name"
            type="text"
            placeholder="Name"
            onChangeHandler={handleOnChange}
            label="Name"
          />
        </StyledFlex>
        <StyledFlex>
          <Textarea
            label="Add Description."
            placeholder="Add Description."
            //   value={text}
            onChange={handleOnChange}
          />
        </StyledFlex>
      </FormLayout>
    </MainLayout>
  );
};

export default PlatformUserTypeMasterForm;
