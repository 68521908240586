import React from "react";
import { useEffect, useState } from "react";
import "./pagination.css";

export function Pagination(props) {
  const {
    currentPage = 0,
    totalRecords,
    perPage,
    pageChangeHandler,
    disabled = false,
    hideRecordDetails = false,
    fontSizerStyle = {},
  } = props;

  const [pageNumberSelect, setPageNumberSelect] = useState(
    parseInt(currentPage, 10)
  );

  let paginationStyle = {
    pageCount: {},
    pageNumber: {},
  };

  if (fontSizerStyle?.fontSize) {
    paginationStyle = {
      pageNumber: {
        fontSize: fontSizerStyle?.fontSize + 2,
      },
      pageCount: {
        fontSize: fontSizerStyle?.fontSize,
      },
    };
  }

  const totalPages = Math.ceil(totalRecords / perPage);
  let previous = parseInt(pageNumberSelect, 10) - 1;
  let next = parseInt(pageNumberSelect, 10) + 1;
  if (previous === 0) previous = 1;
  if (next === totalPages + 1) next = totalPages;
  const start = (pageNumberSelect - 1) * perPage + 1;

  const end =
    totalRecords < start + parseInt(perPage, 10) - 1
      ? totalRecords
      : start + parseInt(perPage, 10) - 1;
  let pageRecords = [];

  if (totalPages > 6) {
    if (pageNumberSelect < 5) {
      pageRecords = [1, 2, 3, 4, 5, "...", totalPages];
    } else {
      if (next === totalPages - 2) {
        pageRecords = [
          1,
          "...",
          previous,
          pageNumberSelect,
          next,
          next + 1,
          totalPages,
        ];
      } else if (next === totalPages - 1) {
        pageRecords = [
          1,
          "...",
          previous - 1,
          previous,
          pageNumberSelect,
          next,
          totalPages,
        ];
      } else if (next === totalPages && pageNumberSelect !== totalPages) {
        pageRecords = [
          1,
          "...",
          previous - 2,
          previous - 1,
          previous,
          pageNumberSelect,
          next,
        ];
      } else if (next === totalPages && pageNumberSelect === totalPages) {
        pageRecords = [
          1,
          "...",
          pageNumberSelect - 4,
          pageNumberSelect - 3,
          pageNumberSelect - 2,
          pageNumberSelect - 1,
          pageNumberSelect,
        ];
      } else {
        pageRecords = [
          1,
          "...",
          previous,
          pageNumberSelect,
          next,
          "...",
          totalPages,
        ];
      }
    }
  } else {
    for (let i = 1; i <= totalPages; i++) {
      pageRecords.push(i);
    }
  }
  const pageClickHandler = (e) => {
    if (!disabled) {
      const clickedPage = e.target.getAttribute("data");
      if (clickedPage === "...") return;
      const isPageChanged = clickedPage !== pageNumberSelect;
      setPageNumberSelect(Number(e.target.getAttribute("data")));

      if (isPageChanged) {
        pageChangeHandler(e.target.getAttribute("data"));
      }
    }
  };

  useEffect(() => {
    setPageNumberSelect(currentPage);
  }, [currentPage]);

  return (
    <div className="pagination">
      <div className="page_nav">
        <ul className="page_numbers">
          {pageRecords.map((pageNumber) => {
            return (
              <li key={pageNumber} onClick={pageClickHandler}>
                <span
                  href="##"
                  data={pageNumber}
                  className={`page_numbers ${
                    pageNumber === pageNumberSelect
                      ? "page--current"
                      : pageNumber === "..."
                      ? "blank"
                      : ""
                  }`}
                  onClick={(e) => e.preventDefault()}
                  style={paginationStyle?.pageNumber}
                >
                  {pageNumber}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
      {totalRecords > 0 && !disabled && !hideRecordDetails ? (
        <span className="page_count" style={paginationStyle?.pageCount}>
          Showing records {start} to {end} of {totalRecords}
        </span>
      ) : null}
    </div>
  );
}
