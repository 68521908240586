import Dropdown from "components/CustomDropdown/Dropdown";
import Input from "components/CustomInput";
import Textarea from "components/CustomTextarea";
import { StyledFlex } from "constants/commonStyles";
import { ROUTE_CONSTANTS } from "constants/routeConstant";
import { useForm } from "hooks/useForm";
import FormLayout from "layouts/FormLayout";
import MainLayout from "layouts/MainLayout";
import React, { useState } from "react";

const ProgramMasterForm = ({ isAdd, isEdit, formHeading, isView }) => {
  const initialState = {
    email: {
      value: "",
      //   required: true,
      error: "",
    },
    password: {
      value: "",
      error: "",
    },
  };

  const handleGradeMasterSubmission = (params, error) => {
    if (isAdd) {
      console.log("add");
    } else {
      console.log("edit");
    }
  };

  const { errors, handleOnSubmit, handleOnChange, handleOnSelection } = useForm(
    initialState,
    handleGradeMasterSubmission
  );

  const options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ];

  return (
    <MainLayout>
      <FormLayout
        formHeading={formHeading}
        handleOnSubmit={handleOnSubmit}
        cancelBtnText={"Cancel"}
        cancelBtnRoute={ROUTE_CONSTANTS.PROGRAM_MASTER}
        saveBtnText={"Save"}
        isView={isView}
        viewButtonText={"Edit"}
        viewFormEditRoute={ROUTE_CONSTANTS.EDIT_PROGRAM_MASTER}
      >
        <StyledFlex>
          <Input
            name="programName"
            type="text"
            placeholder="Program Name"
            onChangeHandler={handleOnChange}
            label="Grade Name"
          />
          <Textarea
            label="Add Description."
            placeholder="Add Description."
            //   value={text}
            onChange={handleOnChange}
          />
        </StyledFlex>
        <StyledFlex>
          <Dropdown
            label={"Select Grade"}
            options={options}
            placeholder="Select Grade"
            onChange={handleOnSelection}
            allowClear
          />
          <Input
            name="schoolType"
            type="text"
            placeholder="School Type"
            onChangeHandler={handleOnChange}
            label="School Type"
          />
        </StyledFlex>
        <StyledFlex>
          <Dropdown
            label={"Select School Type"}
            options={options}
            placeholder="Select School Type"
            onChange={handleOnSelection}
            allowClear
          />
          <Dropdown
            label={"Select School Category (cycle) dropdown"}
            options={options}
            placeholder="Select School Category (cycle) dropdown"
            onChange={handleOnSelection}
            allowClear
          />
        </StyledFlex>
        <StyledFlex>
          <Dropdown
            label={"School Status dropdown"}
            options={options}
            placeholder="School Status dropdown"
            onChange={handleOnSelection}
            allowClear
          />
          <Dropdown
            label={"Select School Education Type"}
            options={options}
            placeholder="Select School Education Type"
            onChange={handleOnSelection}
            allowClear
          />
        </StyledFlex>
      </FormLayout>
    </MainLayout>
  );
};

export default ProgramMasterForm;
