import styled from "styled-components";

const ButtonContainer = styled.div`
  display: inline-flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
`;

const ButtonPart = styled.button`
  background-color: ${(props) =>
    props?.variant === "accept" ? "#28a745" : "#dc3545"};
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  flex: 1;
  height: ${(props) => props?.height || "40"}px;
  width: ${(props) => props?.width || "100"}px;
  padding: 0;
  &:hover {
    background-color: ${(props) =>
      props?.variant === "accept" ? "#218838" : "#c82333"};
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const ButtonTitle = styled.div`
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  font-size: 16px;
  flex: 2;
  text-align: center;
`;

const SwapButtonStyles = {
  ButtonContainer,
  ButtonPart,
  ButtonTitle,
};

export default SwapButtonStyles;
