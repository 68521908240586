import React from "react";
import AuthLayout from "layouts/AuthLayout";
import { Container } from "styled-bootstrap-grid";
import Input from "components/CustomInput";
import Button from "components/CustomButton";
import { useForm } from "hooks/useForm";
import "layouts/AuthLayout/authStyles.css";
import AuthComponentsHeader from "components/AuthComponentsHeader";

const ForgotPasswordComponent = () => {
  const initialState = {
    email: {
      value: "",
      //   required: true,
      error: "",
    },
  };

  const forgotPasswordHandler = (params, error) => {};

  const { handleOnSubmit, handleOnChange } = useForm(
    initialState,
    forgotPasswordHandler
  );

  return (
    <AuthLayout>
      <Container>
        <div className="auth_wrapper">
          <div className="auth_inner_wrapper">
            <AuthComponentsHeader authComponentHeader={"Forgot Password"} />
            <Input
              name="email"
              type="email"
              placeholder="Email"
              onChangeHandler={handleOnChange}
              label="Email"
            />
            <Button
              btnText="Submit"
              onClickHandler={handleOnSubmit}
              btnMarginTop
              width={100}
            />
          </div>
        </div>
      </Container>
    </AuthLayout>
  );
};

export default ForgotPasswordComponent;
