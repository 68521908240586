import { ConfigProvider, Tabs } from "antd";
import React, { useState } from "react";
import NationListing from "./NationListing";
import { useModal } from "hooks/useModal";
import CustomModal from "components/CustomModal";
import Button from "components/CustomButton";
import RegionListing from "./RegionListing";
import "./tabStyles.css";
import ProvinceListing from "./ProvinceListing";
import CommuneListing from "./CommuneListing";
import VillageListing from "./VillageListing";
import AddNation from "./AddNation";
import AddRegion from "./AddRegion";
import AddProvince from "./AddProvince";
import AddCommune from "./AddCommune";
import AddVillage from "./AddVillage";

const MasterManagementTabs = () => {
  const { isModalOpen, openModal, closeModal, modalType } = useModal();
  const [activeTab, setActiveTab] = useState("1");

  const handleAddButtonClick = () => {
    openModal(activeTab);
  };

  const onChange = (key) => {
    setActiveTab(key);
  };

  const renderModalContent = () => {
    switch (modalType) {
      case "1":
        return <AddNation />;
      case "2":
        return <AddRegion />;
      case "3":
        return <AddProvince />;
      case "4":
        return <AddCommune />;
      case "5":
        return <AddVillage />;
      default:
        return null;
    }
  };

  const items = [
    {
      key: "1",
      label: "Nation",
      children: (
        <>
          <div className="tab_children_wrapper">
            <Button
              onClickHandler={handleAddButtonClick}
              btnText={"Add nation"}
              width={100}
              btnMarginTop={"16px"}
            />
          </div>
          <NationListing />
        </>
      ),
      itemColor: "red",
    },
    {
      key: "2",
      label: "Region",
      children: (
        <>
          <div className="tab_children_wrapper">
            <Button
              onClickHandler={handleAddButtonClick}
              btnText={"Add Region"}
              width={100}
              btnMarginTop={"16px"}
            />
          </div>
          <RegionListing />
        </>
      ),
    },
    {
      key: "3",
      label: "Province",
      children: (
        <>
          <div className="tab_children_wrapper">
            <Button
              onClickHandler={handleAddButtonClick}
              btnText={"Add Province"}
              width={100}
              btnMarginTop={"16px"}
            />
          </div>
          <ProvinceListing />
        </>
      ),
    },
    {
      key: "4",
      label: "Commune",
      children: (
        <>
          <div className="tab_children_wrapper">
            <Button
              onClickHandler={handleAddButtonClick}
              btnText={"Add Commune"}
              width={100}
              btnMarginTop={"16px"}
            />
          </div>
          <CommuneListing />
        </>
      ),
    },
    {
      key: "5",
      label: "Village/Sector",
      children: (
        <>
          <div className="tab_children_wrapper">
            <Button
              onClickHandler={handleAddButtonClick}
              btnText={"Add Village/Sector"}
              width={100}
              btnMarginTop={"16px"}
            />
          </div>
          <VillageListing />
        </>
      ),
    },
  ];

  const getModalTitle = (activeTab) => {
    switch (activeTab) {
      case "1":
        return "Nation";
      case "2":
        return "Region";
      case "3":
        return "Province";
      case "4":
        return "Commune";
      case "5":
        return "Village/Sector";
      default:
        return "Unknown";
    }
  };

  return (
    <div>
      <ConfigProvider
        theme={{
          token: {
            itemColor: "red",
            borderRadius: 2,
          },
        }}
      >
        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
          inkBar={true}
        />
      </ConfigProvider>

      {isModalOpen(activeTab) ? (
        <CustomModal
          modalOpen={isModalOpen(activeTab)}
          setModalOpen={() => closeModal(activeTab)}
          modalTitle={`Add ${getModalTitle(activeTab)}`}
          modalInnerContent={renderModalContent()}
          footerBtnText={"Save"}
        />
      ) : null}
    </div>
  );
};

export default MasterManagementTabs;
