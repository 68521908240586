import React from "react";
import Button from "components/CustomButton";
import "./tableHeaderStyles.css";

const TableHeader = ({
  tableHeader,
  showAddButton,
  addBtnText,
  handleAddRoute,
  btnMarginTop,
}) => {
  return (
    <div className="table_header_wrapper">
      <div className="table_header_inner_wrapper">
        <p className="table_header_para">{tableHeader}</p>
        {showAddButton ? (
          <Button
            btnText={addBtnText}
            onClickHandler={handleAddRoute}
            btnMarginTop={btnMarginTop}
            width={100}
          />
        ) : null}
      </div>
    </div>
  );
};

export default TableHeader;
