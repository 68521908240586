import React from "react";
import AuthLayout from "layouts/AuthLayout";
import { Container } from "styled-bootstrap-grid";
import { useForm } from "hooks/useForm";
import Input from "components/CustomInput";
import Button from "components/CustomButton";
import Checkbox from "components/CustomCheckbox";
import { useNavigate } from "react-router-dom";
import AuthComponentsHeader from "components/AuthComponentsHeader";
import "layouts/AuthLayout/authStyles.css";
import { ROUTE_CONSTANTS } from "constants/routeConstant";

const Login = () => {
  const navigate = useNavigate();

  const initialState = {
    email: {
      value: "",
      //   required: true,
      error: "",
    },
    password: {
      value: "",
      error: "",
    },
  };

  const loginHandler = (params, error) => {
    navigate(ROUTE_CONSTANTS.DASHBOARD);
  };

  const { errors, handleOnSubmit, handleOnChange } = useForm(
    initialState,
    loginHandler
  );

  return (
    <AuthLayout>
      <Container>
        <div className="auth_wrapper">
          <div className="auth_inner_wrapper">
            <AuthComponentsHeader
              authComponentHeader={"Sign in your account"}
            />
            <Input
              name="email"
              type="email"
              placeholder="Email"
              onChangeHandler={handleOnChange}
              label="Email"
            />
            <Input
              name="password"
              type="password"
              placeholder="Password"
              onChangeHandler={handleOnChange}
              label="Password"
            />
            <div className="auth_login_forgot_password_div">
              <span
                className="auth_forgot_password_span"
                onClick={() => navigate("/forgot-password")}
              >
                Forgot Password?
              </span>
            </div>
            <Button
              btnText="Log In"
              onClickHandler={handleOnSubmit}
              btnMarginTop
              width={100}
            />
            <div>
              <Checkbox checkboxLabel="Remember me" />
            </div>
          </div>
        </div>
      </Container>
    </AuthLayout>
  );
};

export default Login;
