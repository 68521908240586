import React from "react";
import checkboxStyles from "./checkboxStyles.module.css";

const Checkbox = ({ checkboxLabel }) => {
  return (
    <div className={checkboxStyles.checkbox_wrapper}>
      <input className={checkboxStyles.checkbox_styles} type="checkbox" />
      <label className={checkboxStyles.checkboxLabel_styles}>
        {checkboxLabel}
      </label>
    </div>
  );
};

export default Checkbox;
