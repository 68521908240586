export const FieldTypes = {
  TYPE_TEXT: "text",
  TYPE_NUMBER: "number",
  TYPE_EMAIL: "email",
  TYPE_FILE: "file",
  TYPE_PASSWORD: "password",
  TYPE_RADIO: "radio",
  TYPE_CHECKBOX: "checkbox",
  TYPE_EDITOR: "editor",
};
