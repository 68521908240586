import React from "react";
import AuthLayout from "layouts/AuthLayout";
import { Container } from "styled-bootstrap-grid";
import { useNavigate } from "react-router-dom";
import { useForm } from "hooks/useForm";
import Input from "components/CustomInput";
import Button from "components/CustomButton";
import AuthComponentsHeader from "components/AuthComponentsHeader";
import { ROUTE_CONSTANTS } from "constants/routeConstant";

const CreateNewPassword = () => {
  const navigate = useNavigate();

  const initialState = {
    email: {
      value: "",
      //   required: true,
      error: "",
    },
    password: {
      value: "",
      error: "",
    },
  };

  const handleCreateNewPassword = (params, error) => {
    navigate(ROUTE_CONSTANTS.HOME);
  };

  const { errors, handleOnSubmit, handleOnChange } = useForm(
    initialState,
    handleCreateNewPassword
  );

  return (
    <AuthLayout>
      <Container>
        <div className="auth_wrapper">
          <div className="auth_inner_wrapper">
            <AuthComponentsHeader authComponentHeader={"Create Password"} />
            <Input
              name="newPassword"
              type="email"
              placeholder="New Password"
              onChangeHandler={handleOnChange}
              label="New Password"
            />
            <Input
              name="confirmPassword"
              type="password"
              placeholder="Confirm Password"
              onChangeHandler={handleOnChange}
              label="Confirm Password"
            />
            <Button
              btnText="Save"
              onClickHandler={handleOnSubmit}
              btnMarginTop
              width={100}
            />
          </div>
        </div>
      </Container>
    </AuthLayout>
  );
};

export default CreateNewPassword;
