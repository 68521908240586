import Dropdown from "components/CustomDropdown/Dropdown";
import Input from "components/CustomInput";
import { useForm } from "hooks/useForm";
import React, { useState } from "react";

const AddVillage = () => {
  const [selectedValue, setSelectedValue] = useState(null);
  const initialState = {
    email: {
      value: "",
      //   required: true,
      error: "",
    },
    password: {
      value: "",
      error: "",
    },
  };

  const handleAddVillage = (params, error) => {
    // logic for save
  };

  const { errors, handleOnSubmit, handleOnChange } = useForm(
    initialState,
    handleAddVillage
  );

  const handleDropdownChange = (value) => {
    setSelectedValue(value);
    console.log("Selected Value:", value);
  };

  const options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ];

  return (
    <>
      <Input
        name="nameInEnglish"
        type="text"
        placeholder="Name (English)"
        onChangeHandler={handleOnChange}
        label="Name (English)"
      />
      <Input
        name="nameInFrench"
        type="text"
        placeholder="Name (French)"
        onChangeHandler={handleOnChange}
        label="Name (French)"
      />
      <Dropdown
        label={"Select Commune"}
        options={options}
        placeholder="Select Commune"
        value={selectedValue}
        onChange={handleDropdownChange}
        allowClear
      />
    </>
  );
};

export default AddVillage;
