import MainLayout from "layouts/MainLayout";
import React from "react";
import MasterManagementTabs from "./MasterManagementTabs";

const MasterManagement = () => {
  return (
    <MainLayout>
      <MasterManagementTabs />
    </MainLayout>
  );
};

export default MasterManagement;
