import Dropdown from "components/CustomDropdown/Dropdown";
import Input from "components/CustomInput";
import Textarea from "components/CustomTextarea";
import { StyledFlex } from "constants/commonStyles";
import { ROUTE_CONSTANTS } from "constants/routeConstant";
import { useForm } from "hooks/useForm";
import FormLayout from "layouts/FormLayout";
import MainLayout from "layouts/MainLayout";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const GradeMasterForm = ({ isAdd, isEdit, formHeading, isView }) => {
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState(null);

  const initialState = {
    email: {
      value: "",
      //   required: true,
      error: "",
    },
    password: {
      value: "",
      error: "",
    },
  };

  const handleGradeMasterSubmission = (params, error) => {
    if (isAdd) {
      console.log("add");
    } else {
      console.log("edit");
    }
  };

  const { errors, handleOnSubmit, handleOnChange } = useForm(
    initialState,
    handleGradeMasterSubmission
  );

  const handleDropdownChange = (value) => {
    setSelectedValue(value);
    console.log("Selected Value:", value);
  };

  const options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ];

  return (
    <MainLayout>
      <FormLayout
        formHeading={formHeading}
        handleOnSubmit={handleOnSubmit}
        cancelBtnText={"Cancel"}
        cancelBtnRoute={ROUTE_CONSTANTS.GRADE_MASTER}
        saveBtnText={"Save"}
        isView={isView}
        viewButtonText={"Edit"}
        viewFormEditRoute={ROUTE_CONSTANTS.EDIT_GRADE_MASTER}
      >
        <StyledFlex>
          <Input
            name="gradeName"
            type="text"
            placeholder="Grade Name"
            onChangeHandler={handleOnChange}
            label="Grade Name"
          />
          <Input
            name="schoolType"
            type="text"
            placeholder="School Type"
            onChangeHandler={handleOnChange}
            label="School Type"
          />
        </StyledFlex>
        <StyledFlex>
          <Dropdown
            label={"Category (CYCLE)"}
            options={options}
            placeholder="Category (CYCLE)"
            value={selectedValue}
            onChange={handleDropdownChange}
            allowClear
          />
          <Textarea
            label="Add Description."
            placeholder="Add Description."
            //   value={text}
            onChange={handleOnChange}
          />
        </StyledFlex>
        <StyledFlex>
          <Dropdown
            label={"School/University/Institute Status"}
            options={options}
            placeholder="School/University/Institute Status"
            value={selectedValue}
            onChange={handleDropdownChange}
            allowClear
          />
          <Dropdown
            label={"Type"}
            options={options}
            placeholder="Type"
            value={selectedValue}
            onChange={handleDropdownChange}
            allowClear
          />
        </StyledFlex>
      </FormLayout>
    </MainLayout>
  );
};

export default GradeMasterForm;
