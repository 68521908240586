import { useState, useCallback } from "react";

const modalName = ["nation", "other", "congo", "filter"];

export const useModal = () => {
  const [modalType, setModalType] = useState(null);
  const [openModals, setOpenModals] = useState(new Set(modalName));

  const openModal = useCallback((name) => {
    setModalType(name);
    setOpenModals((prev) => new Set(prev).add(name));
  }, []);

  const closeModal = useCallback((name) => {
    setModalType(null);
    setOpenModals((prev) => {
      const updated = new Set(prev);
      updated.delete(name);
      return updated;
    });
  }, []);

  const isModalOpen = useCallback((name) => openModals.has(name), [openModals]);

  return { openModal, closeModal, isModalOpen, modalType };
};
