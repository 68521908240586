import CustomModal from "components/CustomModal";
import { CustomTable } from "components/CustomTable";
import RenderTableActionsButton from "components/RenderTableActionsButton";
import MainLayout from "layouts/MainLayout";
import React, { useState } from "react";
import RenderSchoolFacilitiesMasterFilters from "./RenderSchoolFacilitiesMasterFilters";
import { STRING_CONSTANT } from "constants/common";
import { useNavigate } from "react-router-dom";
import { ROUTE_CONSTANTS } from "constants/routeConstant";

const SchoolFacilitiesMaster = () => {
  const navigate = useNavigate();
  const defaultModalProps = { isOpen: false, data: null };
  const [isFilter, setIsFilter] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const columns = [
    {
      field: "id",
      header: "Id",
    },
    {
      field: "schoolsFacilityName",
      header: "Schools",
      enableSort: true,
    },
    { field: "description", header: "Description" },
    { field: "category", header: "Category (cycle)" },
    {
      field: "status",
      header: "Status",
    },

    {
      field: "createdDate",
      header: "Created date",
    },
    {
      field: "updatedDate",
      header: "Updated date",
    },
    {
      field: "action",
      header: "Actions",
      shouldRenderElement: true,
      renderElement: () => {
        return (
          <RenderTableActionsButton
            showViewIcon
            showEditIcon
            showDeleteIcon
            setModalOpen={setModalOpen}
            switchChecked={switchChecked}
            handleOnSwitch={handleOnSwitch}
            handleDeleteClick={() => setModalOpen({ data, isOpen: true })}
            handleEditRoute={ROUTE_CONSTANTS.EDIT_SCHOOL_FACILITIES}
            handleViewRoute={ROUTE_CONSTANTS.VIEW_SCHOOL_FACILITIES}
          />
        );
      },
    },
  ];

  const data = [
    {
      id: "1",
      schoolsFacilityName: "John Brown",
      category: "avc",
      status: "Active",
      description: "jkj",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      id: "2",
      schoolsFacilityName: "Jim Green",
      category: "avc",
      status: "Active",
      description: "jkj",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      id: "3",
      schoolsFacilityName: "Joe Black",
      category: "avc",
      status: "Active",
      description: "jkj",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      id: "2",
      schoolsFacilityName: "Jim Green",
      category: "avc",
      status: "Inactive",
      description: "jkj",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      id: "3",
      schoolsFacilityName: "Joe Black",
      category: "avc",
      status: "Inactive",
      description: "jkj",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      id: "2",
      schoolsFacilityName: "Jim Green",
      category: "avc",
      status: "Active",
      description: "jkj",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      id: "3",
      schoolsFacilityName: "Joe Black",
      category: "avc",
      status: "Inactive",
      description: "jkj",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      id: "2",
      schoolsFacilityName: "Jim Green",
      category: "avc",
      status: "Active",
      description: "jkj",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
  ];

  const onChangeData = () => {};

  const handleDateChange = (date, dateString) => {};

  const handleMultiSelectChange = (value) => {};

  const multiSelectOptions = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
  ];

  const handleIsFilter = () => {
    setIsFilter(!isFilter);
  };

  const handleAddRoute = (params, error) => {
    navigate(ROUTE_CONSTANTS.ADD_SCHOOL_FACILITIES);
  };

  const handleOnSwitch = (e, key) => {
    setSwitchChecked(() => e?.target?.checked);
  };

  return (
    <MainLayout>
      {modalOpen.isOpen ? (
        <CustomModal
          modalOpen={modalOpen.isOpen}
          setModalOpen={() => setModalOpen(defaultModalProps)}
          modalTitle={"Delete Modal"}
          modalInnerContent={STRING_CONSTANT.RECORD_DELETE_STRING}
          footerBtnText={"Yes"}
        />
      ) : null}

      <CustomTable
        showAddButton={true}
        handleAddRoute={handleAddRoute}
        isFilter={isFilter}
        addBtnText={"Add"}
        tableHeader={"School Facilities Master"}
        handleIsFilter={handleIsFilter}
        filterOptions={
          <RenderSchoolFacilitiesMasterFilters
            showDateFilter={true}
            showMultiSelectFilter={true}
            multiSelectOptions={multiSelectOptions}
          />
        }
        data={data}
        columns={columns}
        count={10}
        width={"100"}
        noDataFoundMessage={"not found"}
        class={"table_class"}
        onChangeData={onChangeData}
        btnMarginTop={false}
      />
    </MainLayout>
  );
};

export default SchoolFacilitiesMaster;
