import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./sideNavStyles.css";
import { MENULIST } from "constants/sidenavMenuItemList";
import { EdulacLogo, EdulacSymbol } from "assets/images/SVGs";
import { SideNavStyledComponent } from "./sideNavStyles";

const SideNav = ({ isCollapsed }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentRoute = location.pathname;
  const {
    SidebarMainWrapper,
    SidebarList,
    SidebarItem,
    IconWrapper,
    SideNavMenuDiv,
    SideNavMenuItemSpan,
  } = SideNavStyledComponent;

  return (
    <SidebarMainWrapper isCollapsed={isCollapsed}>
      <div className="side_nav_logo">
        <p>{isCollapsed ? <EdulacSymbol /> : <EdulacLogo />}</p>
      </div>
      <SidebarList isCollapsed={isCollapsed}>
        {MENULIST?.map((menuItem, index) => {
          const isActive = currentRoute === menuItem.path;
          return (
            <SidebarItem
              onClick={() => navigate(menuItem.path)}
              key={`side_nav_${index}`}
              isActive={isActive}
            >
              <SideNavMenuDiv isCollapsed={isCollapsed}>
                <IconWrapper>{menuItem?.icon}</IconWrapper>
                {!isCollapsed ? (
                  <SideNavMenuItemSpan isCollapsed={isCollapsed}>
                    {menuItem?.text}
                  </SideNavMenuItemSpan>
                ) : null}
              </SideNavMenuDiv>
            </SidebarItem>
          );
        })}
      </SidebarList>
    </SidebarMainWrapper>
  );
};

export default SideNav;
