import React from "react";
import { Menu, Dropdown } from "antd";
import {
  Bell,
  IconHamburger,
  LogoutSvg,
  Profile,
  RightArrowSvg,
  Setting,
} from "assets/images/SVGs";
import navbarStyles from "./navbarStyles.module.css";
import { useNavigate } from "react-router-dom";
import { ROUTE_CONSTANTS } from "constants/routeConstant";
import "./navbarStyles.css";

const NavBar = ({ toggleSidebar, isCollapsed }) => {
  const navigate = useNavigate();

  const logoutHandler = () => {
    navigate(ROUTE_CONSTANTS.HOME);
  };

  const menu = (
    <Menu>
      <Menu.Item key={"menu_" + 1}>
        <span>My Account</span>
      </Menu.Item>
      <Menu.Item key={"menu_" + 2}>
        <span>Change Password</span>
      </Menu.Item>
      <Menu.Item key={"menu_" + 3}>
        <span>Sync-Activity</span>
      </Menu.Item>
      <Menu.Item key={"menu_" + 4} onClick={logoutHandler}>
        <span className={navbarStyles.logout_text}>Sign Out</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={navbarStyles.navbar_wrapper}>
      <div onClick={toggleSidebar}>
        {isCollapsed ? (
          <RightArrowSvg width={15} height={15} />
        ) : (
          <IconHamburger width={15} height={15} />
        )}
      </div>
      <div className={navbarStyles.navbar_right_side}>
        <p className={navbarStyles.navbar_profile_name}>Hi, Riddhi</p>
        <Bell width={20} height={20} />
        <Setting width={20} height={20} />

        <Dropdown
          overlay={menu}
          trigger={["click"]}
          placement="bottomRight"
        >
          <div onClick={(e) => e.preventDefault()}>
            <Profile width={20} height={20} />
          </div>
        </Dropdown>

        <LogoutSvg width={20} height={20} onClick={logoutHandler} />
      </div>
    </div>
  );
};

export default NavBar;
