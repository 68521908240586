import React from "react";
import { Modal } from "antd";
import Button from "components/CustomButton";
import "./modalStyles.css";

const CustomModal = ({
  modalOpen,
  setModalOpen,
  modalTitle,
  modalInnerContent,
  footerBtnText,
}) => {
  return (
    <Modal
      title={modalTitle}
      centered
      open={modalOpen}
      onOk={() => setModalOpen(false)}
      onCancel={() => setModalOpen(false)}
      footer={[
        <div className="modal_footer_wrapper">
          <Button
            btnText={"Cancel"}
            onClickHandler={() => setModalOpen(false)}
          />
          <Button
            btnText={footerBtnText}
            onClickHandler={() => setModalOpen(false)}
          />
        </div>,
      ]}
    >
      <p className="modal_inner_content">{modalInnerContent}</p>
    </Modal>
  );
};

export default CustomModal;
