import React, { useState } from "react";
import SideNav from "components/DashboardSideNav";
import NavBar from "components/NavBar";
import mainLayoutStyles from "./mainLayoutStyles.module.css";
import { MainLayoutLeftPanel, MainLayoutRightPanel } from "./MainLayoutStyles";

const MainLayout = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <div className={mainLayoutStyles.main_layout_wrapper}>
      <div className={mainLayoutStyles.main_layout_inner_wrapper}>
        <MainLayoutLeftPanel isCollapsed={isCollapsed}>
          <SideNav isCollapsed={isCollapsed} />
        </MainLayoutLeftPanel>
        <MainLayoutRightPanel isCollapsed={isCollapsed}>
          <NavBar
            toggleSidebar={() => setIsCollapsed(!isCollapsed)}
            isCollapsed={isCollapsed}
          />
          <div className={mainLayoutStyles.main_layout_children_wrapper}>
            {children}
          </div>
        </MainLayoutRightPanel>
      </div>
    </div>
  );
};

export default MainLayout;
