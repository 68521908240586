import React from "react";
import { StyledButton } from "./buttonStyles";

const Button = ({ btnText, onClickHandler, btnMarginTop, width }) => {
  return (
    <div>
      <StyledButton
        width={width}
        onClick={() => onClickHandler()}
        btnMarginTop={btnMarginTop}
      >
        {btnText}
      </StyledButton>
    </div>
  );
};

export default Button;
