import React from "react";
import inputStyles from "./inputStyles.module.css";

const Input = ({ name, value, placeholder, onChangeHandler, label }) => {
  return (
    <div className={inputStyles.input_wrapper}>
      <label className={inputStyles.label_styles}>{label}</label>
      <input
        className={inputStyles.input}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChangeHandler}
      />
    </div>
  );
};

export default Input;
