import { STRING_CONSTANT } from "constants/common";
import React from "react";

export function NoDataToDisplay(props) {
  return (
    <div className="emptyArea">
      <p className="no-results-found">
        {props.message ?? STRING_CONSTANT.NO_RESULTS_FOUND}
      </p>
    </div>
  );
}
