import React from "react";
import "./CustomSwitch.css";

export function CustomSwitch(props) {
  const {
    isChecked = false,
    onChangeHandler,
    fieldName,
    title,
    style = {},
    labelClass = "",
    titleClass = "",
    classes,
    disabled = false,
    disableAutoFocus = false,
  } = props;

  return (
    <div className={`field-group field--inline ${classes ? classes : ""}`}>
      <label
        className={`field-group_label ${labelClass}`}
        style={style && { color: style["text-color"] }}
      >
        <b className={titleClass}>{title}</b>
      </label>
        <div className="switch-button">
          <input
            id="checkbox-switch"
            type="checkbox"
            className="checkbox"
            onChange={onChangeHandler}
            name={fieldName}
            checked={isChecked ? "checked" : ""}
            disabled={disabled}
            autoFocus={!disableAutoFocus}
          />
          <div className="knobs"></div>
          <div className="layer"></div>
        </div>
    </div>
  );
}
