import { DatePicker, Select } from "antd";
import React from "react";

const RenderSchoolTypeMasterFilters = (
  multiSelectOptions = [],
  onDateChange,
  onMultiSelectChange
) => {
  return (
    <div className="filter_wrapper_container">
      <DatePicker onChange={onDateChange} className="filter_width" />
      <Select
        mode="multiple"
        allowClear
        className="filter_width"
        placeholder="Filter by category"
        onChange={onMultiSelectChange}
        options={multiSelectOptions}
        showSearch
      />
      <Select
        mode="multiple"
        allowClear
        className="filter_width"
        placeholder="Filter by school status"
        onChange={onMultiSelectChange}
        options={multiSelectOptions}
        showSearch
      />
      <Select
        mode="multiple"
        allowClear
        className="filter_width"
        placeholder="Filter by status"
        onChange={onMultiSelectChange}
        options={multiSelectOptions}
        showSearch
      />
      <Select
        mode="multiple"
        allowClear
        className="filter_width"
        placeholder="Filter by school type"
        onChange={onMultiSelectChange}
        options={multiSelectOptions}
        showSearch
      />
    </div>
  );
};

export default RenderSchoolTypeMasterFilters;
