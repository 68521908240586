import React from "react";
import styles from "./authComponentStyles.module.css";

const AuthComponentsHeader = ({ authComponentHeader }) => {
  return (
    <>
      <div className={styles.auth_component_header_wrapper}>Edulac</div>
      <h4 className={styles.auth_component_h4}>{authComponentHeader}</h4>
    </>
  );
};

export default AuthComponentsHeader;
