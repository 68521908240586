import React from "react";
import Button from "components/CustomButton";
import { useNavigate } from "react-router-dom";
import formLayoutStyles from "./formLayoutStyles.module.css";

const FormLayout = ({
  children,
  formHeading,
  handleOnSubmit,
  cancelBtnText,
  cancelBtnRoute,
  saveBtnText,
  isView,
  viewButtonText,
  viewFormEditRoute,
}) => {
  const navigate = useNavigate();

  return (
    <div className={formLayoutStyles.form_layout_main_wrapper}>
      <div className={formLayoutStyles.form_layout_header_text}>
        <div className={formLayoutStyles.form_layout_inner_wrapper}>
          <p className={formLayoutStyles.form_layout_header}>{formHeading}</p>
        </div>
      </div>
      {children}

      {!isView ? (
        <div className={formLayoutStyles.form_button_layout}>
          <Button
            btnText={cancelBtnText}
            onClickHandler={() => navigate(cancelBtnRoute)}
            btnMarginTop
            width={100}
          />
          <Button
            btnText={saveBtnText}
            onClickHandler={handleOnSubmit}
            btnMarginTop
            width={100}
          />
        </div>
      ) : (
        <div
          className={formLayoutStyles.button_wrapper_for_view_form_edit_button}
        >
          <Button
            btnText={viewButtonText}
            onClickHandler={() => navigate(viewFormEditRoute)}
            btnMarginTop
            width={"auto"}
          />
        </div>
      )}
    </div>
  );
};

export default FormLayout;
