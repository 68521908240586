export const ASC = "ASC";
export const DESC = "DESC";

export const STRING_CONSTANT = {
  RECORD_DELETE_STRING: "Are you sure you want to delete the record?",
  NO_RESULTS_FOUND: "No results found.",
  VIEW_PLATFORM_USER_TYPE_MASTER: "View Platform User Type Master",
  ADD_PLATFORM_USER_TYPE_MASTER: "Add Platform User Type Master",
  EDIT_PLATFORM_USER_TYPE_MASTER: "Edit Platform User Type Master",
  EDIT_GRADE_MASTER: "Edit Grade Master",
  ADD_GRADE_MASTER: "Add Grade Master",
  VIEW_GRADE_MASTER: "View Grade Master",
  ADD_PROGRAM_MASTER: "Add Program Master",
  EDIT_PROGRAM_MASTER: "Edit Program Master",
  VIEW_PROGRAM_MASTER: "View Program Master",
  ADD_SUBJECT_MASTER: "Add Subject Master",
  EDIT_SUBJECT_MASTER: "Edit Subject Master",
  VIEW_SUBJECT_MASTER: "View Subject Master",
  ADD_SCHOOL_FACILITIES: "Add School Facilities",
  EDIT_SCHOOL_FACILITIES: "Edit School Facilities",
  VIEW_SCHOOL_FACILITIES: "View School Facilities",
};
