import React, { useState } from "react";
import MainLayout from "layouts/MainLayout";
import CustomModal from "components/CustomModal";
import { CustomTable } from "components/CustomTable";
import RenderProgramMasterFilters from "./RenderProgramMasterFilters";
import RenderTableActionsButton from "components/RenderTableActionsButton";
import { STRING_CONSTANT } from "constants/common";
import { useNavigate } from "react-router-dom";
import { ROUTE_CONSTANTS } from "constants/routeConstant";

const ProgramMaster = () => {
  const navigate = useNavigate();
  const [isFilter, setIsFilter] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);
  const defaultModalProps = { isOpen: false, data: null };
  const [modalOpen, setModalOpen] = useState(false);

  const columns = [
    {
      field: "id",
      header: "Id",
    },
    {
      field: "name",
      header: "Name",
      enableSort: true,
    },
    { field: "schoolCategory", header: "School Category" },
    {
      field: "schoolType",
      header: "School Type",
    },
    {
      field: "educationType",
      header: "Educational Type",
    },
    {
      field: "createdDate",
      header: "Created date",
    },
    {
      field: "updatedDate",
      header: "Updated date",
    },
    {
      field: "action",
      header: "Actions",
      shouldRenderElement: true,
      renderElement: () => {
        return (
          <RenderTableActionsButton
            setModalOpen={setModalOpen}
            switchChecked={switchChecked}
            handleOnSwitch={handleOnSwitch}
            handleDeleteClick={() => setModalOpen({ data, isOpen: true })}
            showViewIcon
            showDeleteIcon
            showEditIcon
            handleViewRoute={ROUTE_CONSTANTS.VIEW_PROGRAM_MASTER}
            handleEditRoute={ROUTE_CONSTANTS.EDIT_PROGRAM_MASTER}
          />
        );
      },
    },
  ];

  const data = [
    {
      id: "1",
      name: "John Brown",
      schoolCategory: "avc",
      schoolType: "jkj",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      id: "2",
      name: "Jim Green",
      schoolCategory: "avc",
      schoolType: "jkj",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      id: "3",
      name: "Joe Black",
      schoolCategory: "avc",
      schoolType: "jkj",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      id: "2",
      name: "Jim Green",
      schoolCategory: "avc",
      schoolType: "jkj",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      id: "3",
      name: "Joe Black",
      schoolCategory: "avc",
      schoolType: "jkj",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      id: "2",
      name: "Jim Green",
      schoolCategory: "avc",
      schoolType: "jkj",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      id: "3",
      name: "Joe Black",
      schoolCategory: "avc",
      schoolType: "jkj",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      id: "2",
      name: "Jim Green",
      schoolCategory: "avc",
      schoolType: "jkj",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
  ];

  const onChangeData = () => {};

  const handleDateChange = (date, dateString) => {};

  const handleMultiSelectChange = (value) => {};

  const multiSelectOptions = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
  ];

  const handleIsFilter = () => {
    setIsFilter(!isFilter);
  };

  const handleAddRoute = (params, error) => {
    navigate(ROUTE_CONSTANTS.ADD_PROGRAM_MASTER);
  };

  const handleOnSwitch = (e, key) => {
    setSwitchChecked(() => e?.target?.checked);
  };

  return (
    <MainLayout>
      {modalOpen.isOpen ? (
        <CustomModal
          modalOpen={modalOpen.isOpen}
          setModalOpen={() => setModalOpen(defaultModalProps)}
          modalTitle={"Delete Modal"}
          modalInnerContent={STRING_CONSTANT.RECORD_DELETE_STRING}
          footerBtnText={"Yes"}
        />
      ) : null}
      <CustomTable
        showAddButton={true}
        handleAddRoute={handleAddRoute}
        isFilter={isFilter}
        addBtnText={"Add"}
        tableHeader={"Program Master"}
        handleIsFilter={handleIsFilter}
        filterOptions={
          <RenderProgramMasterFilters
            showDateFilter={true}
            showMultiSelectFilter={true}
            multiSelectOptions={multiSelectOptions}
          />
        }
        data={data}
        columns={columns}
        count={10}
        width={"100"}
        noDataFoundMessage={"not found"}
        class={"table_class"}
        onChangeData={onChangeData}
        btnMarginTop={false}
      />
    </MainLayout>
  );
};

export default ProgramMaster;
