import CustomModal from "components/CustomModal";
import { CustomTable } from "components/CustomTable";
import RenderTableActionsButton from "components/RenderTableActionsButton";
import { STRING_CONSTANT } from "constants/common";
import { ROUTE_CONSTANTS } from "constants/routeConstant";
import MainLayout from "layouts/MainLayout";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import RenderBannerManagementFilters from "./RenderBannerManagementFilters";

const BannerManagement = () => {
  const navigate = useNavigate();
  const defaultModalProps = { isOpen: false, data: null };
  const [isFilter, setIsFilter] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const columns = [
    {
      field: "id",
      header: "Id",
    },
    {
      field: "bannerTitle",
      header: "Banner Title",
      enableSort: true,
    },
    {
      field: "bannerImageIconEnglish",
      header: "Banner Image (English)",
    },
    { field: "bannerImageIconFrench", header: "Banner Image (French)" },
    {
      field: "bannerThumbnail",
      header: " Banner thumbnail",
    },
    {
      field: "bannerSequence",
      header: "Banner Sequence",
    },
    {
      field: "bannerPlace",
      header: "Banner Place",
    },

    { field: "defaultBannerFlag", header: "Default Banner flag" },
    {
      field: "createdBy",
      header: "Created By",
    },
    {
      field: "createdAt",
      header: "Created At",
    },
    {
      field: "updatedBy",
      header: "Updated By",
    },
    {
      field: "updatedAt",
      header: "Updated At",
    },
    {
      field: "action",
      header: "Actions",
      shouldRenderElement: true,
      renderElement: () => {
        return (
          <RenderTableActionsButton
            setModalOpen={setModalOpen}
            switchChecked={switchChecked}
            handleOnSwitch={handleOnSwitch}
            handleDeleteClick={() => setModalOpen({ data, isOpen: true })}
            handleEditRoute={ROUTE_CONSTANTS.EDIT_GRADE_MASTER}
            showViewIcon
            showEditIcon
            showDeleteIcon
          />
        );
      },
    },
  ];

  const data = [
    {
      id: "1",
      bannerTitle: "John Brown",
      bannerImageIconFrench: "avc",
      bannerThumbnail: "Active",
      bannerImageIconEnglish: "jkj",
      bannerSequence: "formal",
      bannerPlace: "ijiij",
      defaultBannerFlag: "gg",
      createdBy: "Riddhi",
      createdAt: "12/09/2024",
      updatedBy: "18/10/2024",
      updatedAt: "18/10/2024",
    },
    {
      id: "2",
      bannerTitle: "Jim Green",
      bannerImageIconFrench: "avc",
      bannerThumbnail: "Active",
      bannerImageIconEnglish: "jkj",
      bannerSequence: "formal",
      defaultBannerFlag: "gg",
      bannerPlace: "ijiij",
      createdBy: "Riddhi",
      createdAt: "12/09/2024",
      updatedBy: "18/10/2024",
      updatedAt: "18/10/2024",
    },
    {
      id: "3",
      bannerTitle: "Joe Black",
      bannerImageIconFrench: "avc",
      bannerThumbnail: "Active",
      bannerImageIconEnglish: "jkj",
      bannerSequence: "formal",
      bannerPlace: "ijiij",
      defaultBannerFlag: "gg",
      createdBy: "Riddhi",
      createdAt: "12/09/2024",
      updatedBy: "18/10/2024",
      updatedAt: "18/10/2024",
    },
    {
      id: "2",
      bannerTitle: "Jim Green",
      bannerImageIconFrench: "avc",
      bannerThumbnail: "Inactive",
      bannerImageIconEnglish: "jkj",
      bannerSequence: "formal",
      bannerPlace: "ijiij",
      defaultBannerFlag: "gg",
      createdBy: "Riddhi",
      createdAt: "12/09/2024",
      updatedBy: "18/10/2024",
      updatedAt: "18/10/2024",
    },
    {
      id: "3",
      bannerTitle: "Joe Black",
      bannerImageIconFrench: "avc",
      bannerThumbnail: "Inactive",
      bannerImageIconEnglish: "jkj",
      bannerSequence: "formal",
      bannerPlace: "ijiij",
      createdBy: "Riddhi",
      createdAt: "12/09/2024",
      updatedBy: "18/10/2024",
      updatedAt: "18/10/2024",
    },
    {
      id: "2",
      bannerTitle: "Jim Green",
      bannerImageIconFrench: "avc",
      bannerThumbnail: "Active",
      bannerImageIconEnglish: "jkj",
      bannerSequence: "formal",
      bannerPlace: "ijiij",
      defaultBannerFlag: "gg",
      createdBy: "Riddhi",
      createdAt: "12/09/2024",
      updatedBy: "18/10/2024",
      updatedAt: "18/10/2024",
    },
    {
      id: "3",
      bannerTitle: "Joe Black",
      bannerImageIconFrench: "avc",
      bannerThumbnail: "Inactive",
      bannerImageIconEnglish: "jkj",
      bannerSequence: "formal",
      bannerPlace: "ijiij",
      defaultBannerFlag: "gg",
      createdBy: "Riddhi",
      createdAt: "12/09/2024",
      updatedBy: "18/10/2024",
      updatedAt: "18/10/2024",
    },
    {
      id: "2",
      bannerTitle: "Jim Green",
      bannerImageIconFrench: "avc",
      bannerThumbnail: "Active",
      bannerImageIconEnglish: "jkj",
      bannerSequence: "formal",
      bannerPlace: "ijiij",
      defaultBannerFlag: "gg",
      createdBy: "Riddhi",
      createdAt: "12/09/2024",
      updatedBy: "18/10/2024",
      updatedAt: "18/10/2024",
    },
  ];

  const onChangeData = () => {};

  const handleDateChange = (date, dateString) => {};

  const handleMultiSelectChange = (value) => {};

  const multiSelectOptions = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
  ];

  const handleIsFilter = () => {
    setIsFilter(!isFilter);
  };

  const handleAddRoute = (params, error) => {
    navigate(ROUTE_CONSTANTS.ADD_GRADE_MASTER);
  };

  const handleOnSwitch = (e, key) => {
    setSwitchChecked(() => e?.target?.checked);
  };

  return (
    <MainLayout>
      {modalOpen.isOpen ? (
        <CustomModal
          modalOpen={modalOpen.isOpen}
          setModalOpen={() => setModalOpen(defaultModalProps)}
          modalTitle={"Delete Modal"}
          modalInnerContent={STRING_CONSTANT.RECORD_DELETE_STRING}
          footerBtnText={"Yes"}
        />
      ) : null}

      <CustomTable
        showAddButton={true}
        handleAddRoute={handleAddRoute}
        isFilter={isFilter}
        addBtnText={"Add"}
        tableHeader={"Banner Management"}
        handleIsFilter={handleIsFilter}
        filterOptions={
          <RenderBannerManagementFilters
            showDateFilter={true}
            showMultiSelectFilter={true}
            multiSelectOptions={multiSelectOptions}
          />
        }
        data={data}
        columns={columns}
        count={10}
        width={"100"}
        noDataFoundMessage={"not found"}
        class={"table_class"}
        onChangeData={onChangeData}
        btnMarginTop={false}
      />
    </MainLayout>
  );
};

export default BannerManagement;
