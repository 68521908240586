import styled from "styled-components";

export const StyledButton = styled.button`
  border-color: #393e46;
  background-color: #393e46;
  outline: none;
  padding: 0.5rem 0.9rem;
  border-radius: 9px;
  color: #eeeeee;
  font-weight: 600;
  width: ${({ width }) => width + "%"};
  margin-top: ${({ btnMarginTop }) => (btnMarginTop ? "1rem" : "0")};
  border: none;
  cursor: pointer;

  :hover {
    background-color: #3945f8;
    border-color: #3945f8;
    opacity: 1;
    transform: translateY(0);
    transition-duration: 0.35s;
  }
`;
