import React, { useState } from "react";
import SwapButtonStyles from "./swapButtonStyles";

export function CustomSwapButton(props) {
  const {
    onAccept,
    onReject,
    isDisabled,
    acceptLabel = "Accept",
    rejectLabel = "Reject",
    buttonHeight,
    buttonWidth,
  } = props;
  const [selected, setSelected] = useState(null);

  const handleAccept = () => {
    setSelected("accept");
    if (onAccept) {
      onAccept();
    }
  };

  const handleReject = () => {
    setSelected("reject");
    if (onReject) {
      onReject();
    }
  };

  return (
    <SwapButtonStyles.ButtonContainer>
      <SwapButtonStyles.ButtonPart
        variant="accept"
        onClick={handleAccept}
        disabled={isDisabled || selected === "accept"}
        height={buttonHeight}
        width={buttonWidth}
      >
        {acceptLabel}
      </SwapButtonStyles.ButtonPart>
      <SwapButtonStyles.ButtonPart
        variant="reject"
        onClick={handleReject}
        disabled={isDisabled || selected === "reject"}
        height={buttonHeight}
        width={buttonWidth}
      >
        {rejectLabel}
      </SwapButtonStyles.ButtonPart>
    </SwapButtonStyles.ButtonContainer>
  );
}
