import {
  get_prop_values,
  is_micro_field_required,
  is_object,
  is_required,
} from "../constants/utilities";
import { useState, useEffect, useCallback } from "react";

/**
 * Custom hooks to validate your Form...
 *
 * @param {object} stateSchema model you stateSchema.
 * @param {object} stateValidatorSchema model your validation.
 * @param {function} submitFormCallback function to be execute during form submission.
 */
export const useForm = (
  stateSchema,
  submitFormCallback,
  specificValidationFields
) => {
  const [state, setStateSchema] = useState({ ...stateSchema });
  const [values, setValues] = useState(get_prop_values(state, "value"));
  const [errors, setErrors] = useState(get_prop_values(state, "error"));
  const [dirty, setDirty] = useState(get_prop_values(state));
  const [disable, setDisable] = useState(true);
  const [isDirty, setIsDirty] = useState(false);

  // Get a local copy of stateSchema
  useEffect(() => {
    setStateSchema(stateSchema);
    setDisable(true); // Disable button in initial render.
    setInitialErrorState();
  }, []); // eslint-disable-line

  const updateSchema = (schema) => {
    setStateSchema(schema);
  };

  // For every changed in our state this will be fired
  // To be able to disable the button
  useEffect(() => {
    if (isDirty) {
      setDisable(validateErrorState());
    }
  }, [errors, isDirty]); // eslint-disable-line

  const setRequired = (fieldName) => {
    if (!stateSchema[fieldName]) return;

    let field = stateSchema[fieldName];
    field.required = true;
  };

  const setValidator = (fieldName, validatorFunction) => {
    if (!stateSchema[fieldName]) return;

    let field = stateSchema[fieldName];
    field.validator = validatorFunction;
  };

  // Validate fields in forms
  const validateFormFields = useCallback(
    (name, value) => {
      // const validator = stateValidatorSchema;
      // Making sure that stateValidatorSchema name is same in
      // stateSchema

      if (!stateSchema[name]) return;
      const field = stateSchema[name];

      let error = "";
      error = is_required(value, field.required, " This is required field");

      if (is_object(field["validator"]) && error === "") {
        if (value && value.length > 0) {
          const fieldValidator = field["validator"];
          // Test the function callback if the value is meet the criteria
          const testFunc = fieldValidator["func"];
          if (testFunc) {
            if (!testFunc(value, values)) {
              error = fieldValidator["error"];
            }
          } else {
            error = fieldValidator["error"];
          }
        }
      }

      return error;
    },
    [state, values]
  );

  // Validate microsite fields in forms
  const validateMicroSiteFormFields = (name, value, type) => {
    if (!stateSchema[name]) return;
    const field = stateSchema[name];
    let error = "";
    const isFieldToConsider = (name) => {
      if (specificValidationFields.length === 0) return true;
      else if (
        specificValidationFields.length > 0 &&
        specificValidationFields.includes(name)
      )
        return true;
      else return false;
    };
    if (isFieldToConsider(name)) {
      error = is_micro_field_required(
        value,
        field.required,
        "This is required field",
        type
      );
      if (is_object(field["validator"]) && error === "") {
        if (value && value.length > 0) {
          const fieldValidator = field["validator"];
          // Test the function callback if the value is meet the criteria
          const testFunc = fieldValidator["func"];
          if (testFunc) {
            if (field.type === "TELEPHONE_WITH_EXT") {
              if (Array.isArray(value)) {
                const newVal = value.join("");
                if (!testFunc(newVal, values)) {
                  error = fieldValidator["error"];
                }
              } else if (!testFunc(value, values)) {
                error = fieldValidator["error"];
              }
            } else {
              if (!testFunc(value, values)) {
                error = fieldValidator["error"];
              }
            }
          } else {
            error = fieldValidator["error"];
          }
        }
      }
    }
    return error;
  };

  // Set Initial Error State
  // When hooks was first rendered...
  const setInitialErrorState = useCallback(() => {
    Object.keys(errors).forEach((name) => {
      errors[name] = validateFormFields(name, values[name]);
      setErrors((prevState) => ({
        ...prevState,
        [name]: validateFormFields(name, values[name]),
      }));
    });
  }, [errors, values, validateFormFields]);

  const setInitialErrorsForForm = useCallback(
    (initialState) => {
      Object.keys(initialState).forEach((name) =>
        setErrors((prevState) => ({
          ...prevState,
          [name]: validateFormFields(name, values[name]),
        }))
      );
    },
    [errors, values, validateFormFields]
  );

  // Used to disable submit button if there's a value in errors
  // or the required field in state has no value.
  // Wrapped in useCallback to cached the function to avoid intensive memory leaked
  // in every re-render in component

  const validateErrorState = useCallback(
    () => Object.values(errors).some((error) => error),
    [errors]
  );

  // Event handler for handling changes in input.
  const handleOnChange = useCallback(
    (event) => {
      setIsDirty(true);

      const name = event.target.name;
      const value = event.target.value;

      const error = validateFormFields(name, value);
      setValues((prevState) => ({ ...prevState, [name]: value }));
      setErrors((prevState) => ({ ...prevState, [name]: error }));
      setDirty((prevState) => ({ ...prevState, [name]: error !== "" }));
    },
    [validateFormFields]
  );

  const handleOnNumberChange = useCallback(
    (event) => {
      setIsDirty(true);
      const name = event.target.name;
      const value = event.target.valueAsNumber;
      const error = validateFormFields(name, event.target.value);
      setValues((prevState) => ({ ...prevState, [name]: value }));
      setErrors((prevState) => ({ ...prevState, [name]: error }));
      setDirty((prevState) => ({ ...prevState, [name]: error !== "" }));
    },
    [validateFormFields]
  );

  // Event handler for handling changes in input.
  const handleOnSelection = useCallback(
    (event, allowEmptyVal = false) => {
      setIsDirty(true);

      const name = event.target?.name;
      let value = event.target?.value;
      if (value || allowEmptyVal) {
        const error = validateFormFields(name, value);
        setValues((prevState) => ({ ...prevState, [name]: value }));
        setErrors((prevState) => ({ ...prevState, [name]: error }));
        setDirty((prevState) => ({ ...prevState, [name]: true }));
      }
    },
    [validateFormFields]
  );

  const handleOnFileUpload = useCallback(
    (event) => {
      setIsDirty(true);

      let stateName = "file";
      let stateValue = event;
      let error = validateFormFields(stateName, stateValue);

      setValues((prevState) => ({
        ...prevState,
        [stateName]: stateValue,
      }));
      setErrors((prevState) => ({ ...prevState, [stateName]: error }));
      setDirty((prevState) => ({ ...prevState, [stateName]: true }));

      let sName = "documents";
      let sValue = event;
      let sError = validateFormFields(sName, sValue);

      setValues((prevState) => ({ ...prevState, [sName]: sValue }));
      setErrors((prevState) => ({ ...prevState, [sName]: sError }));
      setDirty((prevState) => ({ ...prevState, [sName]: true }));
    },
    [validateFormFields]
  );

  const handleOnRadioChange = (e) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.id;

    const error = validateFormFields(name, value);
    if (value !== null || value !== undefined) {
      setValues((prevState) => ({ ...prevState, [name]: value }));
      setErrors((prevState) => ({ ...prevState, [name]: error }));
      setDirty((prevState) => ({ ...prevState, [name]: true }));
    }
  };

  const handleOnMultipleSelection = useCallback(
    (name, selectedValues) => {
      setIsDirty(true);
      if (selectedValues) {
        const error = validateFormFields(name, selectedValues);
        setValues((prevState) => ({
          ...prevState,
          [name]: selectedValues,
        }));
        setErrors((prevState) => ({ ...prevState, [name]: error }));
        setDirty((prevState) => ({ ...prevState, [name]: true }));
      }
    },
    [validateFormFields]
  );

  const setErrorsAndDirty = (
    keysToSet,
    errorOrReset = "reset",
    errorMessages = []
  ) => {
    let errorObjToSet, dirtyObjectToSet;
    keysToSet.forEach((key, index) => {
      errorObjToSet = {
        ...errorObjToSet,
        [key]:
          errorOrReset === "error"
            ? errorMessages[index]
              ? errorMessages[index]
              : "This is required field"
            : "",
      };
      dirtyObjectToSet = {
        ...dirtyObjectToSet,
        [key]: errorOrReset === "error",
      };
    });

    setErrors((prevState) => ({ ...prevState, ...errorObjToSet }));
    setDirty((prevState) => ({ ...prevState, ...dirtyObjectToSet }));
  };

  const handleOnCheckboxChange = (
    e,
    selection,
    isDirect = false,
    directKey = ""
  ) => {
    if (isDirect) {
      setValues((prevState) => ({
        ...prevState,
        [directKey]: selection,
      }));
    } else {
      const name = e?.currentTarget?.name;
      const value = e?.currentTarget?.id;
      let checkedValues = [];
      if (selection) {
        if (Array.isArray(selection)) {
          checkedValues = [...selection];
        } else if (selection.length > 0) {
          checkedValues = [selection];
        }
      }

      if (checkedValues.includes(value)) {
        const index = checkedValues.indexOf(value);
        if (index > -1) {
          checkedValues.splice(index, 1); // 2nd parameter means remove one item only
        }
      } else {
        checkedValues = [...checkedValues, value];
      }

      const error = validateFormFields(name, value);
      if (value !== null || value !== undefined) {
        setValues((prevState) => ({
          ...prevState,
          [name]: checkedValues,
        }));
        setErrors((prevState) => ({ ...prevState, [name]: error }));
        setDirty((prevState) => ({ ...prevState, [name]: true }));
      }
    }
  };

  const handleOnMultiselectChange = (value, name) => {
    setDirty(false);

    const error = validateFormFields(name, value);
    if (value !== null || value !== undefined) {
      setValues((prevState) => ({ ...prevState, [name]: value }));
      setErrors((prevState) => ({ ...prevState, [name]: error }));
      setDirty((prevState) => ({ ...prevState, [name]: true }));
    }
  };

  const handleOnValueChange = (value, name) => {
    setDirty(false);

    const error = validateFormFields(name, value);
    if (!value) value = "";
    if (value !== null || value !== undefined) {
      setValues((prevState) => ({ ...prevState, [name]: value }));
      setErrors((prevState) => ({ ...prevState, [name]: error }));
      setDirty((prevState) => ({ ...prevState, [name]: true }));
    }
  };

  const handleOnClear = (object) => {
    setValues((prevState) => ({
      ...prevState,
      ...object,
    }));
  };

  const handleOnSwitch = useCallback(
    (e) => {
      setIsDirty(true);

      const name = e.currentTarget.name;
      const value = e.currentTarget.checked ? true : false;

      const error = validateFormFields(name, value);
      if (value !== null || value !== undefined) {
        setValues((prevState) => ({ ...prevState, [name]: value }));
        setErrors((prevState) => ({ ...prevState, [name]: error }));
        setDirty((prevState) => ({ ...prevState, [name]: true }));
      }
    },
    [validateFormFields]
  );

  const handleOnEditorChange = (e, id) => {
    setDirty(false);

    const name = id;
    const value = e;

    const error = "validateFormFields(name, value)";
    if (value !== null || value !== undefined) {
      setValues((prevState) => ({ ...prevState, [name]: value }));
      setErrors((prevState) => ({ ...prevState, [name]: error }));
      setDirty((prevState) => ({ ...prevState, [name]: true }));
    }
  };

  const handleOnSubmit = useCallback(
    (event) => {
      event?.preventDefault();

      // Making sure that there's no error in the state
      // before calling the submit callback function
      setInitialErrorState();
      if (!validateErrorState()) {
        submitFormCallback(values, null);
      } else {
        Object.keys(errors).forEach((name) => {
          if (errors[name] && errors[name].length > 0) {
            setDirty((prevState) => ({
              ...prevState,
              [name]: true,
            }));
          }
        });
        submitFormCallback(null, errors);
      }
    },
    [validateErrorState, submitFormCallback, values, errors]
  );

  const clearStateSchema = () => {
    Object.keys(values).forEach((name) =>
      setValues((prevState) => ({
        ...prevState,
        [name]: "",
      }))
    );
    Object.keys(errors).forEach((name) =>
      setErrors((prevState) => ({
        ...prevState,
        [name]: "",
      }))
    );
    Object.keys(dirty).forEach((name) =>
      setDirty((prevState) => ({
        ...prevState,
        [name]: false,
      }))
    );
    setInitialErrorState();
  };

  const handleNewSchema = (newSchema) => {
    setStateSchema(newSchema);
    setValues(get_prop_values(newSchema, "value"));
    setErrors(get_prop_values(newSchema, "error"));
    setDirty(get_prop_values(newSchema));
  };

  // to clear the form fields and set error states
  const handleOnDataClear = (name, value = "", stateVal = true) => {
    const error = validateFormFields(name, value);
    setValues((prevState) => ({ ...prevState, [name]: value }));
    setErrors((prevState) => ({ ...prevState, [name]: error }));
    setDirty((prevState) => ({ ...prevState, [name]: stateVal }));
  };

  const clearFieldFromSchema = (name, value = "") => {
    setValues((prevState) => ({ ...prevState, [name]: value }));
    setErrors((prevState) => ({ ...prevState, [name]: "" }));
    setDirty((prevState) => ({ ...prevState, [name]: false }));
  };

  const handleDataClearOnCategoryChange = (nameArray) => {
    nameArray.forEach((name, i) => {
      const error = validateFormFields(name, "");
      setValues((prevState) => ({ ...prevState, [name]: "" }));
      setErrors((prevState) => ({ ...prevState, [name]: error }));
      setDirty((prevState) => ({ ...prevState, [name]: false }));
    });
  };

  const handleCandidateEligibilitySubmit = useCallback(
    (event) => {
      event.preventDefault();
      submitFormCallback(values, errors);
    },
    [submitFormCallback, values, errors]
  );

  const setNewErrorAndDirty = (stateName, errorMessage) => {
    if (errorMessage) {
      setErrors((prevState) => ({
        ...prevState,
        [stateName]: errorMessage,
      }));
      setDirty((prevState) => ({ ...prevState, [stateName]: true }));
    } else {
      setErrors((prevState) => ({ ...prevState, [stateName]: "" }));
      setDirty((prevState) => ({ ...prevState, [stateName]: false }));
    }
  };

  // const setUseFormStates = (vals = null, errs = null, dirt = null) => {
  //   if (vals) {
  //     setValues((prevState) => ({ ...prevState, ...vals }));
  //   }
  //   if (errs) {
  //     setErrors((prevState) => ({ ...prevState, ...errs }));
  //   }
  //   if (dirt) {
  //     setDirty((prevState) => ({ ...prevState, ...dirt }));
  //   }
  // };

  const handleSubmitForm = useCallback(
    (event) => {
      setStateSchema(event);
      // Making sure that there's no error in the state
      // before calling the submit callback function
      setInitialErrorsForForm(event);

      if (!validateErrorState()) {
        submitFormCallback(values, null);
      } else {
        Object.keys(errors).forEach((name) => {
          if (errors[name] && errors[name].length > 0) {
            setDirty((prevState) => ({
              ...prevState,
              [name]: true,
            }));
          }
        });
        submitFormCallback(null, errors);
      }
    },
    [validateErrorState, submitFormCallback, values, errors]
  );

  const handleMicroSiteFormSubmit = (event) => {
    setStateSchema(event);
    const initialErrState = (event) => {
      let err = {};
      Object.keys(event).forEach((name) => {
        const field = event?.[name];
        err[name] = validateMicroSiteFormFields(
          name,
          values[name],
          field?.type
        );
        setErrors((prevState) => ({
          ...prevState,
          [name]: err[name],
        }));
      });
      return err;
    };
    const _errListObj = initialErrState(event);
    const validError = (_errListObj) => {
      return () => Object.values(_errListObj).some((error) => error);
    };
    const CheckError = validError(_errListObj);
    if (!CheckError()) {
      submitFormCallback(values, null);
    } else {
      setErrors(_errListObj);
      Object.keys(_errListObj).forEach((name) => {
        if (_errListObj[name] && _errListObj[name].length > 0) {
          setDirty((prevState) => ({
            ...prevState,
            [name]: true,
          }));
        }
      });
      submitFormCallback(null, _errListObj);
    }
  };

  const handleMicroSiteFormFilled = (event, successCallBack) => {
    setStateSchema(event);
    const initialErrState = (event) => {
      let err = {};
      Object.keys(event).forEach((name) => {
        err[name] = validateMicroSiteFormFields(name, values[name]);
        setErrors((prevState) => ({
          ...prevState,
          [name]: err[name],
        }));
      });
      return err;
    };
    const _errListObj = initialErrState(event);
    const validError = (_errListObj) => {
      return () => Object.values(_errListObj).some((error) => error);
    };
    const CheckError = validError(_errListObj);
    if (!CheckError()) {
      successCallBack(values, null);
    } else {
      setErrors(_errListObj);
      Object.keys(_errListObj).forEach((name) => {
        if (_errListObj[name] && _errListObj[name].length > 0) {
          setDirty((prevState) => ({
            ...prevState,
            [name]: true,
          }));
        }
      });
      successCallBack(null, _errListObj);
    }
  };

  return {
    handleOnChange,
    handleOnNumberChange,
    handleOnSelection,
    handleOnMultipleSelection,
    handleOnSubmit,
    clearStateSchema,
    handleOnMultiselectChange,
    handleOnValueChange,
    values,
    errors,
    disable,
    setValues,
    setErrors,
    setDirty,
    dirty,
    handleOnDataClear,
    clearFieldFromSchema,
    handleNewSchema,
    setNewErrorAndDirty,
    handleSubmitForm,
    updateSchema,
    validateErrorState,
    setErrorsAndDirty,
  };
};
