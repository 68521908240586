import { DatePicker, Select } from "antd";
import React from "react";

const RenderMasterManagementFilters = ({
  multiSelectOptions = [],
  onDateChange,
  onMultiSelectChange,
}) => {
  return (
    <div className="filter_wrapper_container">
      <DatePicker onChange={onDateChange} className="filter_width" />
      <Select
        mode="multiple"
        allowClear
        className="filter_width"
        placeholder="Filter by Status"
        onChange={onMultiSelectChange}
        options={multiSelectOptions}
        showSearch
      />
    </div>
  );
};

export default RenderMasterManagementFilters;
